import classNames from 'classnames';
import React, { useEffect, useRef } from 'react';
import { Button, Form, InputGroup } from 'react-bootstrap';
import { BsSearch } from 'react-icons/bs';
import { ConnectedProps, connect } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { DEFAULTS, ESearchScope, ISearchParams, getObjectSearchParams, removeDefaultParams } from '../../common';
import { scrollWindowToTop } from '../../scrolls';
import { RootState } from '../../store';
import SearchScope from './SearchScope';
import { changeOperativeSearchParams, changeScope, changeSearchingSalt, changeTerm } from './searchSlice';

interface ISearchFormProps extends PropsFromRedux, React.AllHTMLAttributes<HTMLDivElement> {}
const SearchForm = ({
	changeOperativeSearchParams,
	changeScope,
	changeTerm,
	operativeSearchParams,
	changeSearchingSalt,
	className,
}: ISearchFormProps) => {
	const [searchParams] = useSearchParams();
	const refSearch = useRef<HTMLInputElement>(null);
	const navigate = useNavigate();
	const firstTime = useRef(true);
	// const inSearchRoute = useMatch('/');

	useEffect(() => {
		if (!firstTime.current || !!searchParams.get('term')?.trim())
			changeOperativeSearchParams(getObjectSearchParams<ISearchParams>(searchParams, DEFAULTS.searchParams));
		firstTime.current = false;
	}, [searchParams]); // eslint-disable-line

	const onTermChange = (event: React.ChangeEvent<HTMLInputElement>) => changeTerm(event.target.value);

	const onScopeChange = (newScope: ESearchScope) => {
		changeScope(newScope);
		focusInput();
	};

	const focusInput = () => {
		refSearch.current?.focus();
		setTimeout(() => refSearch.current?.select(), 0);
	};

	const onSearchFormSubmit = (event: React.FormEvent) => {
		event.preventDefault();
		event.stopPropagation();
		if (operativeSearchParams.term.trim() === '') return focusInput();
		/**
		 * Параметри можуть містити інформацію, яка залишилась після пошуку за обличчям.
		 * При здійсненні звичайного пошуку вона тут не потрібна, тож ми її видаляємо.
		 */
		const newParams = { ...operativeSearchParams };
		delete newParams.image_no;

		const newSearchParams = new URLSearchParams(removeDefaultParams(newParams, DEFAULTS.searchParams));

		// if (newSearchParams.toString() !== searchParams.toString() || !inSearchRoute)
		// 	navigate(`/?${newSearchParams.toString()}`);
		// else changeSearchingSalt();
		navigate(`/?${newSearchParams.toString()}`, { preventScrollReset: true });
		changeSearchingSalt();
		focusInput();
		scrollWindowToTop();
	};

	useEffect(() => {
		focusInput();
	}, []);

	return (
		<form id="fmSearch" className={classNames('flex-grow-1', className)} role="search" onSubmit={onSearchFormSubmit}>
			<InputGroup>
				<SearchScope scope={operativeSearchParams.scope} onScopeChange={onScopeChange} />
				<Form.Control
					name="term"
					// accessKey="f"
					type="text"
					className="form-control"
					placeholder={`${SEARCH_PLACEHOLDER[operativeSearchParams.scope as string] || 'підстрока'} для пошуку...`}
					maxLength={300}
					autoComplete="on"
					value={operativeSearchParams.term}
					onChange={onTermChange}
					ref={refSearch}
				/>
				<Button variant="warning" type="submit">
					<BsSearch className="icon-lg" />
					<span className="ms-2 d-md-none d-xl-inline">Знайти</span>
				</Button>
			</InputGroup>
		</form>
	);
};

const SEARCH_PLACEHOLDER: { [key: string]: string } = {
	T: 'телефонний номер',
	A: 'автомобільний номер',
	P: 'номер паспорту',
};

const mapState = (state: RootState) => ({
	operativeSearchParams: state.search.operativeSearchParams,
});

const mapDispatch = { changeOperativeSearchParams, changeScope, changeTerm, changeSearchingSalt };

const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(SearchForm);
