import classNames from 'classnames';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import SearchPagination from '../../../components/Pagination';
import { IDocumentAttached } from '../../../interfaces/document';
import AttachedDocumentsActions from './AttachedDocumentsActions';

interface INavAttachedDocumentsProps extends React.AllHTMLAttributes<HTMLDivElement> {
	attached: IDocumentAttached[];
	selectedHash?: string;
	editable: boolean;
	documentId: number;
	realm: string;
	extension: string;
}
const NavAttachedDocuments = ({
	attached,
	selectedHash,
	editable,
	documentId,
	realm,
	extension,
}: INavAttachedDocumentsProps) => {
	const [selectedIndex, setSelectedIndex] = useState<number>(-1);
	const navigate = useNavigate();

	useEffect(() => {
		setSelectedIndex(attached.findIndex(({ document_hash }) => document_hash === selectedHash));
	}, [attached, selectedHash]);

	const onPartClick = useCallback(
		(partNo: number) => {
			if (partNo === selectedIndex) return;
			const document_hash = attached[partNo].document_hash;
			if (!document_hash) return;
			navigate(`../${document_hash}`, { relative: 'path' });
		},
		[attached, navigate, selectedIndex]
	);

	// Якщо редагування заборонене (немає кнопки дій) і дослідження складається лише з одного файлу
	// немає сенсу виводити напис "Частина 1"
	if (!editable && attached.length === 1) return null;

	return (
		<>
			<div className="hstack gap-2">
				{0 < attached.length && selectedIndex !== -1 && (
					<PartsPagination
						pageCount={attached.length}
						activePageNo={selectedIndex}
						pageDelta={2}
						className="mb-0"
						onPageClick={onPartClick}
						preText={
							<span className={classNames('me-1 d-none', attached.length < 5 ? 'd-md-inline' : 'd-lg-inline')}>
								Частина
							</span>
						}
						showAlways
						scrollWhenChanged={false}
					/>
				)}
				{editable && (
					<AttachedDocumentsActions
						documentId={documentId}
						attached={attached}
						selectedIndex={selectedIndex}
						realm={realm}
						selectedHash={selectedHash}
						extension={extension}
					/>
				)}
			</div>
		</>
	);
};

export default NavAttachedDocuments;

const PartsPagination = styled(SearchPagination)`
	--bs-border-width: 0;
	margin-top: 0.12rem;
	.page-link {
		--bs-pagination-padding-y: 0;
		--bs-pagination-padding-x: 0.5rem;
		--bs-pagination-color: RGBA(var(--bs-secondary-rgb), var(--bs-link-opacity, 1)) !important;
		--bs-pagination-hover-color: RGBA(86, 94, 100, var(--bs-link-opacity, 1)) !important;
		--bs-pagination-focus-color: RGBA(86, 94, 100, var(--bs-link-opacity, 1)) !important;
		--bs-pagination-active-color: var(--bs-text);
		--bs-pagination-active-bg: transparent;
		/* &.active{

		} */
		/* &:hover,
		&:focus {
			--bs-pagination-color: RGBA(86, 94, 100, var(--bs-link-opacity, 1)) !important;
		} */
	}
`;
