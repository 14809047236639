import { useEffect } from 'react';
import { Button, ButtonGroup, Dropdown, DropdownButton } from 'react-bootstrap';
import { BsRecord, BsRecord2 } from 'react-icons/bs';
import { showAlert, showError, showPromiseConfirm } from '../../../alerts';
import { DEFAULTS } from '../../../common';
import TextIcon from '../../../components/textIcon';
import { TDocumentStatusOptions } from '../../../interfaces/dict';
import { DOCUMENT_STATUS_COLORS } from '../../../interfaces/document';
import { useDocumentStatusMutation } from '../../services/documents';
import DeleteDocumentButton from '../DeleteDocumentButton';

// interface IDocumentStatusProps extends React.AllHTMLAttributes<HTMLDivElement>{
interface IDocumentStatusProps extends React.AllHTMLAttributes<HTMLButtonElement> {
	current: TDocumentStatusOptions;
	DOCUMENT_STATUS?: { [key in TDocumentStatusOptions]: string };
	editable: boolean;
	closeable: boolean;
	documentId: number;
	documentDataAreValid: () => Promise<boolean>;
	deletionDisabled: boolean;
	statusChangeDisabled: boolean;
}
const DocumentStatus = ({
	current,
	DOCUMENT_STATUS,
	editable,
	closeable,
	documentId,
	documentDataAreValid,
	deletionDisabled,
	statusChangeDisabled,
}: IDocumentStatusProps) => {
	const [updateStatus, updateStatusResult] = useDocumentStatusMutation();

	const onStatusClick = async (event: React.MouseEvent<HTMLButtonElement>) => {
		const { status } = event.currentTarget.dataset as { status: TDocumentStatusOptions };
		if (!status || status === current) return;
		if (['P', 'D'].includes(status) && !(await documentDataAreValid())) return;
		if (
			!(await showPromiseConfirm(
				`Ви дійсно бажаєте змінити статус поточного документа на «${
					DOCUMENT_STATUS ? DOCUMENT_STATUS[status] : status
				}»?`
			))
		)
			return;
		await updateStatus({ status, id: documentId });
	};

	useEffect(() => {
		const { isUninitialized, isError, error, isSuccess, data } = updateStatusResult;
		if (isUninitialized) return;
		if (isSuccess)
			return showAlert(
				`Статус документу оновлено. Поточний статус: «${DOCUMENT_STATUS ? DOCUMENT_STATUS[data.status] : data.status}»`
			);
		// if (isError)
		// 	return showError(
		// 		(error as any).data || <pre>{JSON.stringify(error, undefined, '  ')}</pre>,
		// 		DEFAULTS.updateErrorText
		// 	);
		if (isError) return showError(<pre>{JSON.stringify(error, undefined, '  ')}</pre>, DEFAULTS.updateErrorText);
	}, [updateStatusResult]); // eslint-disable-line

	const strStatus = `Статус: ${DOCUMENT_STATUS ? DOCUMENT_STATUS[current] : current}`;
	return editable ? (
		<DropdownButton
			as={ButtonGroup}
			title={<span className={updateStatusResult.isLoading ? 'animation-bounce' : undefined}>{strStatus}</span>}
			id="ddDocumentStatus"
			variant={DOCUMENT_STATUS_COLORS[current]}
			align="end"
			disabled={updateStatusResult.isLoading}
		>
			{['L', 'W', 'P', 'D'].map((s) => (
				<Dropdown.Item
					key={s}
					as={Button}
					data-status={s}
					onClick={onStatusClick}
					disabled={statusChangeDisabled || s === 'L' || (s === 'D' && !closeable)}
				>
					<TextIcon Icon={s === current ? BsRecord2 : BsRecord}>
						{DOCUMENT_STATUS ? DOCUMENT_STATUS[s as TDocumentStatusOptions] : s}
					</TextIcon>
				</Dropdown.Item>
			))}
			<DeleteDocumentButton documentId={documentId} disabled={deletionDisabled} />
		</DropdownButton>
	) : (
		<span className={`text-${DOCUMENT_STATUS_COLORS[current]}`}>{strStatus}</span>
	);
};

export default DocumentStatus;
