import { useAuth0 } from '@auth0/auth0-react';
import React from 'react';
import { Navbar, NavDropdown } from 'react-bootstrap';
import { BsCheckCircle } from 'react-icons/bs';
import { FaSignOutAlt } from 'react-icons/fa';
import { NavFace } from '../styled';
import DotSpinner from './DotSpinner';
import ThemeButton from './ThemeButton';

const LogoutButton = () => {
	const { isLoading, logout, isAuthenticated, user } = useAuth0();

	const onLogoutClick = async (event: React.MouseEvent<HTMLAnchorElement>) => {
		const { origin, pathname } = window.location;
		logout({ logoutParams: { returnTo: `${origin}${pathname}` } });
	};

	if (isLoading)
		return (
			<Navbar.Text>
				<DotSpinner size="sm">Вхід...</DotSpinner>
			</Navbar.Text>
		);

	if (!isAuthenticated || !user) return null;

	return (
		<NavDropdown
			title={
				<NavFace userIcon>
					<img src={user.picture || '/img/nophoto.webp'} alt="User" />
					<span className="logout__user-name d-inline-block">{user.email}</span>
				</NavFace>
			}
			id="basic-nav-dropdown"
			align="end"
			className="text-nowrap logout__container"
		>
			<NavDropdown.Header>
				{user.email}
				{user.email_verified && <BsCheckCircle className="text-success ms-1" />}
			</NavDropdown.Header>
			<NavDropdown.Divider />
			<ThemeButton />
			<NavDropdown.Item as="button" onClick={onLogoutClick}>
				<FaSignOutAlt className="me-1" />
				Вихід
			</NavDropdown.Item>
		</NavDropdown>
	);
};

export default LogoutButton;
